<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="850"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit PlatForm</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <form id="createForm" class="pa-3 pb-0">
            <div class="row">
              <div class="col-6">
                <v-file-input
                  v-model="formData.image"
                  show-size
                  counter
                  multiple
                  dense
                  outlined
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  label="Image"
                  clearable
                  class="col-12"
                ></v-file-input>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model.trim="$v.formData.name.$model"
                  :error-messages="validateState('name') ? requiredMessage : ''"
                  :counter="40"
                  label="Platform Name"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  :error-messages="
                    validateState('identifier') ? requiredMessage : ''
                  "
                  v-model="formData.identifier"
                  :counter="40"
                  label="Identifier"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model.trim="$v.formData.sourceIdentifier.$model"
                  :error-messages="
                    validateState('sourceIdentifier') ? requiredMessage : ''
                  "
                  :counter="40"
                  label="SourceIdentifier"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model.trim="$v.formData.actionIdentifier.$model"
                  :error-messages="
                    validateState('actionIdentifier') ? requiredMessage : ''
                  "
                  :counter="40"
                  label="ActionIdentifier"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </div>
            </div>
            <v-card>
              <v-toolbar-title
                class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
                >Actions</v-toolbar-title
              >
              <v-card-text>
                <div
                  v-for="(i, index) in itemCount"
                  :key="index"
                  class="d-flex"
                >
                  <v-text-field
                    v-model="formData.actions[index]"
                    :counter="40"
                    label="Action"
                    required
                    dense
                    outlined
                    clearable
                  ></v-text-field>
                  <span class="mt-2"
                    ><v-icon left @click="removeActionItem(index)">
                      mdi-delete
                    </v-icon></span
                  >
                </div>

                <v-btn class="mr-4 mt-2" color="primary" @click="addActions">
                  Add Action
                </v-btn>
              </v-card-text>
            </v-card>
          </form>

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="toggleModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { messages } from "@/core/constants/message";

export default {
  name: "EditPlatform",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      identifier: { required },
      sourceIdentifier: { required },
      actionIdentifier: { required },
      actions: {
        $each: {
          required,
        },
      },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      image: null,
      platform_id: null,
      name: null,
      identifier: null,
      sourceIdentifier: null,
      actionIdentifier: null,
      actions: [],
    },
    itemCount: 0,
  }),
  computed: {
    requiredMessage: function () {
      return messages.requiredMessage;
    },
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
    isDataLoadedFromServer: function () {
      return !!this.itemForAction;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetData();
      }
      this.dialog = !this.dialog;
    },
    addActions() {
      this.itemCount++;
    },
    validationAction() {
      if (this.formData.actions) {
        if (this.itemCount !== this.formData.actions.length) return true;
      }
      return false;
    },
    submitEditForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      if (this.validationAction()) {
        this.message("Error", "Please confirm all action fields", "error");
        return;
      }
      let data = this.convertToFormData();
      this.pageLoader(true);
      ApiService.post("/datahub/platforms/update", data)
        .then(() => {
          this.message(
            "Updated",
            "The Platform data has been updated!",
            "success"
          );
          this.toggleModal();
          this.pageLoader(false);
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      for (var key in this.formData) {
        if (key === "actions") {
          if (this.formData[key].length > 0)
            this.formData[key].map((item) => {
              data.append("actions[]", item);
            });
          else data.append("actions[]", null);
        } else if (key === "image") {
          if (this.formData[key]) data.append(key, this.formData[key][0]);
        } else data.append(key, this.formData[key]);
      }
      return data;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData.platform_id = this.itemForAction.id;
      this.formData.image = null;
      this.formData.name = this.itemForAction.name;
      this.formData.identifier = this.itemForAction.identifier;
      this.formData.sourceIdentifier = this.itemForAction.sourceIdentifier;
      this.formData.actionIdentifier = this.itemForAction.actionIdentifier;
      this.formData.actions = this.itemForAction.platform_actions.data;
      this.itemCount = this.itemForAction.platform_actions.data.length;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    removeActionItem(index) {
      this.formData.actions.splice(index, 1);
      this.itemCount--;
    },
  },
};
</script>
